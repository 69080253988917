import React from "react";
import { Link, graphql } from "gatsby";
import Image from "gatsby-image";
import styled from "styled-components";
//components
import Container from "../components/container";
import Cta from "../components/cta";
import SEO from "../components/SEO";
import LinkUnderlined from "../components/LinkUnderlined";
//images
import hero from "../images/hero_small.jpg";

import slugify from "../slugify";
import user from "../images/user.jpg";
import user2 from "../images/user2.jpg";
//sass
import "../stylesheets/index.sass";

const StyledserviceTiles = styled.section`
    margin: 40px 0;
    .images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        a.service {
            box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
            width: 200px;
            margin: 10px;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            flex-grow: 1;
            text-decoration-line: none;
            overflow: hidden;
            border-radius: 10px;
            @media (max-width: 960px) {
                width: 45%;
                /* margin: 20px 0; */
            }
            @media (max-width: 576px) {
                width: 100%;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0) 50%);
            }
            .gatsby-image-wrapper {
                transition: all 0.4s ease-in-out;
            }
            &:hover .gatsby-image-wrapper {
                transform: scale(1.05);
            }
            .tileText {
                position: absolute;
                width: 100%;
                text-align: center;
                bottom: 0;
                color: #fff;
                z-index: 1;
                font-size: 1.8em;
                margin: 0;
                padding: 5px 0;
                @media (max-width: 576px) {
                    font-size: 1.5em;
                }
            }
        }
    }
`;

const StyledTestimonials = styled.section`
    .testimonial {
        display: flex;
        flex-direction: column;
        justify-content: center;
        blockquote {
            position: relative;
            background: #ededed;
            font-size: 18px;
            width: 60%;
            margin: 50px auto;
            color: #555555;
            padding: 1.2em 30px 1.2em 2.5em;
            border-left: 8px solid #fcc72c;
            line-height: 1.6;
            box-shadow: 0 15px 50px -10px rgba(50, 50, 93, 0.25), 0 8px 30px -15px rgba(0, 0, 0, 0.3);
            box-sizing: border-box;
            @media (max-width: 576px) {
                width: 100%;
            }
            span {
                display: block;
                color: #333333;
                font-style: normal;
                font-weight: bold;
                margin-top: 1em;
            }
            figure img {
                border-radius: 50%;
                position: absolute;
                width: 100px;
                height: auto;
                left: calc(50% - 50px);
                top: -70px;
            }
            &:before {
                content: "\\201C";
                color: #fcc72c;
                font-size: 4em;
                position: absolute;
                left: 10px;
                top: -10px;
            }
        }
    }
`;

const IndexPage = ({ data, location }) => {
    const highlightedServices = data.allContentfulDienstCategorie.edges;

    return (
        <>
            <SEO title="Kwalitatieve behandelingen op maat" path={location.pathname} />
            <section id="hero" style={{ backgroundImage: `url(${hero})` }}>
                <Container>
                    <div className="heroContent">
                        <h1>Kom tot rust bij schoonheidssalon Alegria</h1>
                        <p>
                            Bij Alegria draait alles rond het welzijn van de klant.
                            <br />
                            We zorgen voor een rustige, persoonlijke aanpak met 100% focus op jouw wensen.
                        </p>
                        <Cta to="/diensten">Bekijk onze diensten </Cta>
                    </div>
                </Container>
            </section>
            <Container>
                <section id="services">
                    <div id="intro">
                        <h2>Maak even tijd... voor kwaliteit</h2>
                        <p>
                            We hebben een uitgebreid gamma aan diensten bij Alegria.
                            <br />U kan bij ons terecht voor ontharing, massages, aromatherapie, gelaatsverzorging... en veel meer!
                        </p>
                    </div>

                    <StyledserviceTiles>
                        <div className="images">
                            {highlightedServices.map(service => {
                                const { id, naam, uitgelichteAfbeelding } = service.node;
                                if (id && naam && uitgelichteAfbeelding) {
                                    return (
                                        <Link to={`/diensten/${slugify(naam)}`} key={id} className="service big">
                                            <Image
                                                fluid={uitgelichteAfbeelding.fluid}
                                                alt={uitgelichteAfbeelding.description ? uitgelichteAfbeelding.description : naam}
                                                title={uitgelichteAfbeelding.description ? uitgelichteAfbeelding.description : naam}
                                            />
                                            <h3 className="tileText">{naam}</h3>
                                        </Link>
                                    );
                                }
                            })}
                        </div>
                        <LinkUnderlined style={{ fontSize: "20px", marginLeft: "10px" }} to="/diensten">
                            Bekijk al onze diensten <span style={{ margin: "0 0 2px 4px", fontSize: "14px" }}>&#8594;</span>
                        </LinkUnderlined>
                    </StyledserviceTiles>
                </section>

                <StyledTestimonials>
                    <div className="testimonial">
                        <blockquote>
                            <figure>
                                <img src={user} alt="foto van vrouw" title="tesimonial van een pedicure" />
                            </figure>
                            Ik heb pedicure laten doen. Super mensen en professionele behandeling. Zeer hygiënisch.
                            <span>Heleen</span>
                        </blockquote>
                    </div>
                    <div className="testimonial">
                        <blockquote>
                            <figure>
                                <img src={user2} alt="foto van man" title="tesimonial van een man" />
                            </figure>
                            Een erg lieve jongedame die op zelfstandige basis werkt, boezemt me meteen vertrouwen in. Je merkt ook dat ze de nodige tijd neemt
                            voor haar klanten en erg hygiënisch te werk gaat. Af en toe springt haar moeder bij die kleine taakjes uitoefent.
                            <span>Nikola</span>
                        </blockquote>
                    </div>
                </StyledTestimonials>
            </Container>
        </>
    );
};

export default IndexPage;

export const Imagequery = graphql`
    query {
        allContentfulDienstCategorie(filter: { uitgelicht: { eq: true } }) {
            edges {
                node {
                    id
                    naam
                    uitgelichteAfbeelding {
                        description
                        fluid {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    }
`;
