import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const StyledUnderline = styled.div`
    a {
        display: inline-block;
        position: relative;
        color: #333;
        text-decoration: none;
        margin: 10px 0;
        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            transition: 100ms ease-out 50ms;
            transform-origin: 0 24px;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background: #e1e5ea;
        }
        &:hover {
            &:before {
                transition: 100ms ease-out;
                background: #8995a9;
            }
        }
    }
`;

const LinkUnderlined = ({ to, children, style }) => {
    return (
        <StyledUnderline>
            <Link to={to} style={style}>
                {children}
            </Link>
        </StyledUnderline>
    );
};

export default LinkUnderlined;
