import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

const StyledCta = styled.a`
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    white-space: nowrap;
    background: #FCC72C;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .025em;
    color: #333;
    text-decoration: none;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
    cursor: pointer;
    &:hover {
      background-color: #fdd868;
    }
`

const cta = (props) => {
  return (
    <StyledCta {...props} onClick={() => navigate(`${props.to}`)} role="link" tabIndex="0">
      {props.children}
    </StyledCta>
  )
}

export default cta
